const copyBonus = {
  data() {
    return {
      isBonusCodeCopied: false
    }
  },
  methods: {
    async copyBonusCode(item) {
      await navigator.clipboard.writeText(item)
      this.isBonusCodeCopied = true
    }
  }
}

export default copyBonus
