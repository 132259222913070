<template>
  <div class="content-chip">
    <img
      v-if="imageUrl"
      :alt="alt"
      :src="imageUrl"
      class="chip-image"
    >
    <span v-if="textValue">
      {{ textValue }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BRContentChip',
  props: {
    textValue: {
      type: String,
      required: false,
      default: ''
    },
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    imageUrl: {
      type: String,
      required: false,
      default: ''
    },
    isPaymentChip: {
      type: Boolean,
      required: false,
      default: false
    },
    alt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShowChip: this.checked
    }
  },
  watch: {
    checked(value) {
      this.isShowChip = value
    }
  }
}
</script>

<style lang="scss" scoped>
.content-chip {
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;

  .chip-image {
    border-radius: 6px;
    width: 60px;
    height: 100%;
  }

  &.editable {
    min-width: max-content;
  }
}
</style>
