import { render, staticRenderFns } from "./BRContentChip.vue?vue&type=template&id=b360be26&scoped=true&"
import script from "./BRContentChip.vue?vue&type=script&lang=js&"
export * from "./BRContentChip.vue?vue&type=script&lang=js&"
import style0 from "./BRContentChip.vue?vue&type=style&index=0&id=b360be26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b360be26",
  null
  
)

export default component.exports