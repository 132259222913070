<template>
  <div v-if="isShowPopup" class="bonus-popup">
    <BRIcon icon="copy" />
    <span class="text">
      You copied the
      <span class="primary-text">BONUS CODE</span>
    </span>
    <button class="close-button" @click="closePopup">
      <BRIcon
        icon="close"
        width="11"
        height="11"
        fill-color="var(--default-icon-color)"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'BonusCodePopup',
  props: {
    isShowPopup: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isShowPopup(value) {
      this.$emit('update:isShowPopup', value)

      if (!value) { return }

      setTimeout(() => {
        this.$emit('update:isShowPopup', false)
      }, 5000)
    }
  },
  methods: {
    closePopup() {
      this.$emit('update:isShowPopup', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.bonus-popup {
  position: fixed;
  right: 78px;
  bottom: 70px;
  z-index: 1;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  padding: 14px 16px;
  max-width: 331px;
  box-shadow: var(--box-shadow-default);
  background-color: var(--content-bg-color);

  .text {
    margin-right: 39px;
    margin-left: 20px;
    font-size: var(--font-base);
    color: var(--secondary-text-color);
  }

  .primary-text {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    white-space: nowrap;
    color: var(--primary-text-color);
  }

  .close-button {
    position: absolute;
    right: 16px;
    top: 50%;
    width: 11px;
    height: 11px;
    transform: translateY(-50%);

    svg {
      position: absolute;
      left: 0;
      top: 0;
    }

    @include breakpoint-reverse(small) {
      right: 10px;
      top: 10px;
    }
  }

  @include breakpoint-reverse(small) {
    right: unset;
    bottom: 0;
    border-radius: 0;
    padding: 12px;
    width: 100vw;
    max-width: unset;
  }
}
</style>
